import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import PublicLayout from '../_layouts/public';
import Grid from '@mui/material/Grid';
import { mobilizeiUsersService } from '../_services/mobilizei/users.service';
import useAuthentication from '../_hooks/authentication.hook';
import { CircularProgress } from '@mui/material';
import { showApiError } from '../_helpers/errors';

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(4),
    width: '100%',
  },
}));

export default function LoginPage() {
  const INITIAL_STATE = { email: '', password: '', error: null };

  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles();
  const [formData, setFormData] = useState(INITIAL_STATE);

  const { setToken } = useAuthentication()

  const onSubmit = event => {
    setIsLoading(true)
    const { email, password } = formData;

    mobilizeiUsersService.signIn(email, password)
      .then(response => {
        setToken(response.token);
      })
      .catch(e => {
        showApiError(e.error);
        setIsLoading(false);
      })

    event.preventDefault();
  };

  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <PublicLayout>
      <Grid container display="flex" direction="row" justifyContent="center" spacing={2} paddingX={{ xs: 2, lg: 20 }}>
        <Grid item xs={12} md={6} lg={4}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 6,
            paddingBottom: 10,
            minHeight: '100vh'
          }}
        >
          <img className={classes.logo} src={require('../_assets/img/mobilizei_logo.png')} />
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
              value={formData?.email || ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
              value={formData?.password || ''}
            />
            {isLoading ? (
              <Grid
                container
                justifyContent='center'
                sx={{ mt: 3 }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                sx={{ fontSize: '18px', mt: 3 }}
              >
                Entrar
              </Button>
            )}
            {formData?.error && <p>{formData?.error?.message}</p>}
          </form>
        </Grid>
      </Grid>
    </PublicLayout>
  );
}