import * as React from 'react'

const UserContext = React.createContext()

function useUser() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error(`useUser must be used within a UserContext`);
    }
    return context;
}

function UserProvider({ children }) {
  const [user, setUser] = React.useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider, useUser }