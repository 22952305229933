import { apiFetcher } from "./apiFetcher";

export const mobilizeiConvertrService = {
    createUser,
    getStorePanelUrl,
    getStores,
    updateStoreStatus
};

function createUser(data) {
    const url = `platforms/convertr/users`

    return apiFetcher(url, {
        method: "POST",
        body: JSON.stringify(data)
    }).then(response => response);
}

function getStorePanelUrl(userId) {
    const url = `platforms/convertr/users/${userId}/panel_url`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}

function getStores() {
    const url = `platforms/convertr/stores?limit=999`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}

function updateStoreStatus(storeId, status) {
    const url = `platforms/convertr/stores/${storeId}/status`

    return apiFetcher(url, {
        method: "PUT",
        body: JSON.stringify({ status })
    }).then(response => response);
}