import { useEffect } from "react";
import { PrivateAppRouter, PublicAppRouter } from '../_routers/AppRouter'
import { useUser } from '../_contexts/user.context';
import useAuthentication from '../_hooks/authentication.hook';
import LoadingPage from '../LoadingPage/LoadingPage';
import { isUserAuthorized } from "../_utils/auth";

export default function AppAuthentication() {
    const { isAuthLoading, token, logout } = useAuthentication()
    const { user } = useUser()

    useEffect(() => {
        if(!isUserAuthorized(token, ['admin', 'convertr_admin'])) {
            logout();
        }
    }, [])

    if (isAuthLoading)
        return <LoadingPage />

    if (!user) {
        return <PublicAppRouter />
    }

    return <PrivateAppRouter />
}