import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import StoresPage from '../StoresPage/StoresPage';

export const PublicAppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    )
}

export const PrivateAppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StoresPage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}
