import { apiFetcher } from "./apiFetcher";

export const mobilizeiUsersService = {
  signIn,
  getSelf
};

function signIn(email, password) {
  return apiFetcher(
    'users/signin', {
    method: "POST",
    body: JSON.stringify({ email: email.trim(), password })
  }).then(response => response);
}

function getSelf() {
  return apiFetcher(
    'users/self', {
    method: "GET",
  }).then(response => response);
}