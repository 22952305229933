import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AppAuthentication from './_auth/AppAuthentication';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { GlobalStyles } from '@mui/material';
import { UserProvider } from './_contexts/user.context';

ReactDOM.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <GlobalStyles
        styles={{
          '*': {
            fontFamily: '"Cabin", sans-serif!important'
          },
        }}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ fontSize: 16, width: 600 }}
      />
      <UserProvider>
      <AppAuthentication />
      </UserProvider>
    </ThemeProvider>,
  document.querySelector('#root'),
);