import { decodeJwt } from 'jose';

export const isTokenExpired = (token) => {
    try {
        return Date.now() >= decodeJwt(token).exp * 1000
    } catch {
        return true;
    }
}

export const isUserAuthorized = (token, roles = []) => {
    try {
        return roles.includes(decodeJwt(token).claims.role);
    } catch {
        return true;
    }
}