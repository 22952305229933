import { useEffect, useState } from 'react'
import { isTokenExpired } from '../_utils/auth'
import config from "../_helpers/config";
import { useUser } from '../_contexts/user.context';
import { mobilizeiUsersService } from '../_services/mobilizei/users.service';

export default function useAuthentication() {
    const [isLoading, setIsLoading] = useState(true)

    const [token, setToken] = useState()
    const { user, setUser } = useUser()

    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get('at');

    const localStorageToken = localStorage.getItem(config.APP_AUTH_TOKEN);

    const fetchUser = () => {
        if (!token) return;

        mobilizeiUsersService.getSelf()
            .then(fetchedUser => {
                setUser(fetchedUser);
            })
            .catch(() => {
                setUser(null);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const logout = () => {
        // Clear user, contexts and token data
        setUser(null);
        localStorage.removeItem(config.APP_AUTH_TOKEN);
    }

    useEffect(() => {
        if (token) {
            localStorage.setItem(config.APP_AUTH_TOKEN, token)

            //verify token validity
            const tokenExpired = isTokenExpired(token)

             //logout when token expire
            if(tokenExpired){
                return logout();
            }

            if(!user){
                fetchUser()
            }
        }
    }, [token])

    useEffect(() => {
        // Try to get the access token from the url or the local storage
        const accessToken = urlToken || localStorageToken;

        // Check if the access token is valid
        if (accessToken && isTokenExpired(accessToken)) {
            logout();
        } else if (accessToken) {
            setToken(accessToken);
            return;
        }

        setIsLoading(false)
    }, [])

    return {
        isAuthLoading: isLoading,
        logout,
        token,
        setToken,
        fetchUser
    }
}
